/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `auth` key is used for marking an item on the Sidenav that require authentication to access the link.
  9. The `permissions` key is used for marking an item on the Sidenav that require specified roles to access the link.
  10. The `route` key is used for the "path" parameter of <Route> component.
  11. The `link` key is used for the "to" parameter of <Link> component.
  12. The `href` key is used to store the external links location.
  13. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  14. The `component` key is used to store the component of its route.
*/

import { Navigate } from "react-router-dom";

import AccountProfile from "pages/account/manage/AccountProfile";

//import ManageCompany from "pages/settings/company/manage/ManageCompany";
//import NewUser from "pages/settings/user/new/NewUser";
//import ManageUsers from "pages/settings/users/manage/ManageUsers";
//import ManageRoles from "pages/settings/roles/manage/ManageRoles";

//import CustomerProxy from "pages/customers/manage/CustomerProxy";
//import CustomerRecords from "pages/customers/records/CustomerRecords";
//import QuotationProxy from "pages/quotations/manage/QuotationProxy";
//import QuotationRecords from "pages/quotations/records/QuotationRecords";
//import QuotationPDFView from "pages/quotations/pdf/QuotationPDFView";
//import SalesOrderProxy from "pages/salesorders/manage/SalesOrderProxy";
//import SalesOrderRecords from "pages/salesorders/records/SalesOrderRecords";
//import SalesOrderPDFView from "pages/salesorders/pdf/SalesOrderPDFView";
//import InvoiceProxy from "pages/invoices/manage/InvoiceProxy";
//import InvoiceRecords from "pages/invoices/records/InvoiceRecords";
//import InvoicePDFView from "pages/invoices/pdf/InvoicePDFView";

//import SupplierProxy from "pages/suppliers/manage/SupplierProxy";
//import SupplierRecords from "pages/suppliers/records/SupplierRecords";
//import PurchaseOrderProxy from "pages/purchaseorders/manage/PurchaseOrderProxy";
//import PurchaseOrderRecords from "pages/purchaseorders/records/PurchaseOrderRecords";
//import PurchaseOrderPDFView from "pages/purchaseorders/pdf/PurchaseOrderPDFView";

import SignIn from "pages/authentication/signin";
import SignUp from "pages/authentication/signup";
import VerifyEmail from "pages/authentication/verifyemail";
import ResetPassword from "pages/authentication/resetpassword";

import MDAvatar from "components/atoms/MDAvatar";

// import Icon from "@mui/material/Icon";
//import SettingsIcon from "@mui/icons-material/Settings";
//import GroupsIcon from "@mui/icons-material/Groups";
// import WarehouseIcon from "@mui/icons-material/Warehouse";
//import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
//import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
//import PaidIcon from "@mui/icons-material/Paid";
// import InventoryIcon from "@mui/icons-material/Inventory";
// import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

import { Permission } from "models/abac";

const navigate = (displayName, displayNameKey) => {
  return [
    {
      name: displayName,
      key: displayNameKey,
      auth: true,
      //permissions: Permission.VIEW_MEMBER_DASHBOARD,
      link: `/account/profile`,
      route: `/account/profile`,
      component: <AccountProfile />,
      icon: (
        <MDAvatar bgColor="info" size="sm">
          {displayName?.charAt(0).toUpperCase()}
        </MDAvatar>
      ),
    },

    // {
    //   type: "divider",
    //   key: "divider-dashboard",
    // },
    // {
    //   type: "title",
    //   title: "Dashboard",
    //   key: "title-dashboard",
    // },

    // {
    //   name: "Manager",
    //   key: "dashboard-manager",
    //   auth: true,
    //   permissions: Permission.VIEW_MANAGER_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   route: "/dashboard-manager",
    //   link: "/dashboard-manager",
    //   component: <Sales />,
    // },
    // {
    //   name: "Sales",
    //   key: "dashboard-sales",
    //   auth: true,
    //   permissions: Permission.VIEW_SALES_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   route: "/dashboard-sales",
    //   link: "/dashboard-sales",
    //   component: <Sales />,
    // },
    // {
    //   name: "Purchases",
    //   key: "dashboard-purchases",
    //   auth: true,
    //   permissions: Permission.VIEW_PURCHASES_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   route: "/dashboard-purchases",
    //   link: "/dashboard-purchases",
    //   component: <Sales />,
    // },
    // {
    //   name: "Account",
    //   key: "dashboard-account",
    //   auth: true,
    //   permissions: Permission.VIEW_ACCOUNT_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   route: "/dashboard-account",
    //   link: "/dashboard-account",
    //   component: <Sales />,
    // },
    // {
    //   name: "Finance",
    //   key: "dashboard-finance",
    //   auth: true,
    //   permissions: Permission.VIEW_FINANCE_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   route: "/dashboard-finance",
    //   link: "/dashboard-finance",
    //   component: <Sales />,
    // },
    // {
    //   name: "Service",
    //   key: "dashboard-service",
    //   auth: true,
    //   permissions: Permission.VIEW_SERVICE_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   route: "/dashboard-finance",
    //   link: "/dashboard-finance",
    //   component: <Sales />,
    // },
    {
      type: "divider",
      key: "divider-action",
    },
    {
      type: "title",
      title: "Action",
      key: "title-action",
      permissions: Permission.VIEW_ADMIN_DASHBOARD,
    },
    /*{
      type: "collapse",
      name: "Customers",
      key: "customers",
      permissions: Permission.VIEW_CUSTOMERS_ACTION,
      icon: <GroupsIcon />,
      collapse: [
        {
          name: "New Customer",
          key: "new-customer",
          auth: true,
          permissions: Permission.CREATE_CUSTOMER,
          link: "/customers/manage/new",
          //component: <CustomerProxy />,
        },
        {
          name: "Customer Records",
          key: "customer-records",
          auth: true,
          permissions: Permission.READ_ALL_CUSTOMERS,
          route: "/customers/records",
          link: "/customers/records",
          //component: <CustomerRecords />,
        },
      ],
    },*/
    // {
    //   type: "collapse",
    //   name: "Suppliers",
    //   key: "suppliers",
    //   permissions: Permission.VIEW_SUPPLIERS_ACTION,
    //   icon: <WarehouseIcon />,
    //   collapse: [
    //     {
    //       name: "New Supplier",
    //       key: "new-supplier",
    //       auth: true,
    //       permissions: Permission.CREATE_SUPPLIER,
    //       route: "/samples/account/settings",
    //       link: "/samples/account/settings",
    //       component: <Settings />,
    //     },
    //     {
    //       name: "Records of Suppliers",
    //       key: "records-of-suppliers",
    //       auth: true,
    //       permissions: Permission.READ_ALL_SUPPLIERS,
    //       route: "/samples/account/settings",
    //       link: "/samples/account/settings",
    //       component: <Settings />,
    //     },
    //   ],
    // },
    /*{
      type: "collapse",
      name: "Quotations",
      key: "quotations",
      permissions: Permission.VIEW_QUOTATIONS_ACTION,
      icon: <QuestionAnswerIcon />,
      collapse: [
        {
          name: "New Quotation",
          key: "new-quotation",
          auth: true,
          permissions: Permission.CREATE_QUOTATION,
          link: "/quotations/manage/new",
          //component: <QuotationProxy />,
        },
        {
          name: "Quotation Records",
          key: "quotation-records",
          auth: true,
          permissions: Permission.READ_ALL_QUOTATIONS,
          route: "/quotations/records",
          link: "/quotations/records",
          //component: <QuotationRecords />,
        },
      ],
    },*/
    /*{
      type: "collapse",
      name: "Sales Orders",
      key: "salesorders",
      permissions: Permission.VIEW_SALESORDERS_ACTION,
      icon: <CurrencyExchangeIcon />,
      collapse: [
        {
          name: "New Sales Order",
          key: "new-sales-order",
          auth: true,
          permissions: Permission.CREATE_SALESORDER,
          link: "/salesorders/manage/new",
          //component: <SalesOrderProxy />,
        },
        {
          name: "Sales Order Records",
          key: "sales-order-records",
          auth: true,
          permissions: Permission.READ_ALL_SALESORDERS,
          route: "/salesorders/records",
          link: "/salesorders/records",
          //component: <SalesOrderRecords />,
        },
      ],
    },*/
    /*{
      type: "collapse",
      name: "Invoices",
      key: "invoices",
      permissions: Permission.VIEW_INVOICES_ACTION,
      icon: <PaidIcon />,
      collapse: [
        {
          name: "New Invoice",
          key: "new-invoice",
          auth: true,
          permissions: Permission.CREATE_INVOICE,
          link: "/invoices/manage/new",
          //component: <InvoiceProxy />,
        },
        {
          name: "Invoice Records",
          key: "invoice-records",
          auth: true,
          permissions: Permission.READ_ALL_INVOICES,
          route: "/invoices/records",
          link: "/invoices/records",
          //component: <InvoiceRecords />,
        },
      ],
    },*/
    /*{
      type: "collapse",
      name: "Suppliers",
      key: "suppliers",
      permissions: Permission.VIEW_SUPPLIERS_ACTION,
      icon: <GroupsIcon />,
      collapse: [
        {
          name: "New Supplier",
          key: "new-supplier",
          auth: true,
          permissions: Permission.CREATE_SUPPLIER,
          link: "/suppliers/manage/new",
          //component: <SupplierProxy />,
        },
        {
          name: "Supplier Records",
          key: "supplier-records",
          auth: true,
          permissions: Permission.READ_ALL_SUPPLIERS,
          route: "/suppliers/records",
          link: "/suppliers/records",
          //component: <SupplierRecords />,
        },
      ],
    },*/
    /*{
      type: "collapse",
      name: "Purchase Orders",
      key: "purchaseorders",
      permissions: Permission.VIEW_PURCHASEORDERS_ACTION,
      icon: <CurrencyExchangeIcon />,
      collapse: [
        {
          name: "New Purchase Order",
          key: "new-purchase-order",
          auth: true,
          permissions: Permission.CREATE_PURCHASEORDER,
          link: "/purchaseorders/manage/new",
          //component: <PurchaseOrderProxy />,
        },
        {
          name: "Purchase Order Records",
          key: "purchases-order-records",
          auth: true,
          permissions: Permission.READ_ALL_PURCHASEORDERS,
          route: "/purchaseorders/records",
          link: "/purchaseorders/records",
          //component: <PurchaseOrderRecords />,
        },
      ],
    },*/
    // {
    //   type: "collapse",
    //   name: "Purchase Orders",
    //   key: "purchase-orders",
    //   permissions: Permission.VIEW_PURCHASEORDERS_ACTION,
    //   icon: <InventoryIcon />,
    //   collapse: [
    //     {
    //       name: "New Purchase Order",
    //       key: "new-purchase-order",
    //       auth: true,
    //       permissions: Permission.CREATE_PURCHASEORDER,
    //       route: "/samples/account/settings",
    //       link: "/samples/account/settings",
    //       component: <Settings />,
    //     },
    //     {
    //       name: "Purchase Order Records",
    //       key: "purchase-order-records",
    //       auth: true,
    //       permissions: Permission.READ_ALL_PURCHASEORDERS,
    //       route: "/samples/account/settings",
    //       link: "/samples/account/settings",
    //       component: <Settings />,
    //     },
    //   ],
    // },
    // {
    //   type: "collapse",
    //   name: "Service Reports",
    //   key: "service-reports",
    //   permissions: Permission.VIEW_SERVICEREPORTS_ACTION,
    //   icon: <HomeRepairServiceIcon />,
    //   collapse: [
    //     {
    //       name: "New Service Report",
    //       key: "new-service-report",
    //       auth: true,
    //       permissions: Permission.CREATE_SERVICEREPORT,
    //       route: "/samples/account/settings",
    //       link: "/samples/account/settings",
    //       component: <Settings />,
    //     },
    //     {
    //       name: "Service Report Records",
    //       key: "service-report-records",
    //       auth: true,
    //       permissions: Permission.READ_ALL_SERVICEREPORTS,
    //       route: "/samples/account/settings",
    //       link: "/samples/account/settings",
    //       component: <Settings />,
    //     },
    //   ],
    // },
    {
      type: "divider",
      key: "divider-settings",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
    },
    {
      type: "title",
      title: "Settings",
      key: "title-settings",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
    },
    /*{
      name: "Company",
      key: "manage-company",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
      icon: <SettingsIcon />,
      route: "/settings/company",
      link: "/settings/company",
      //component: <ManageCompany />,
    },*/
    /*{
      name: "New User",
      key: "new-user",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
      icon: <SettingsIcon />,
      route: "/settings/user",
      link: "/settings/user",
      //component: <NewUser />,
    },*/
    /*{
      name: "Users",
      key: "manage-users",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
      icon: <SettingsIcon />,
      route: "/settings/users",
      link: "/settings/users",
      //component: <ManageUsers />,
    },*/
    /*{
      name: "Roles",
      key: "manage-roles",
      auth: true,
      permissions: Permission.VIEW_SUPERADMIN_DASHBOARD,
      icon: <SettingsIcon />,
      route: "/settings/roles",
      link: "/settings/roles",
      //component: <ManageRoles />,
    },*/
    {
      name: "Sign In",
      key: "signin",
      route: "/authentication/signin",
      component: <SignIn />,
    },
    {
      name: "Sign Up",
      key: "sign-up",
      // auth: true,
      // permissions: Permission.PERFORM_SUPERADMIN_ACTION,
      route: "/authentication/signup",
      component: <SignUp />,
    },
    {
      name: "Reset Password",
      key: "reset-password",
      route: "/authentication/resetpassword",
      component: <ResetPassword />,
    },
    {
      name: "Verify Email",
      key: "verify-email",
      auth: true,
      route: "/authentication/verifyemail",
      component: <VerifyEmail />,
    },
    {
      name: "All",
      key: "all",
      auth: true,
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
      route: "*",
      component: <Navigate to={`/account/profile`} />,
    },
    /*{
      name: "Manage Customer",
      key: "manage-customer",
      auth: true,
      route: "/customers/manage/:mode",
      //component: <CustomerProxy />,
    },*/
    /*{
      name: "Manage Customer",
      key: "manage-customer-id",
      auth: true,
      route: "/customers/manage/:mode/:customerId",
      //component: <CustomerProxy />,
    },*/
    /*{
      name: "Manage Quotation",
      key: "manage-quotation",
      auth: true,
      route: "/quotations/manage/:mode",
      //component: <QuotationProxy />,
    },*/
    /*{
      name: "Manage Quotation",
      key: "manage-quotation-id",
      auth: true,
      route: "/quotations/manage/:mode/:quotationId",
      //component: <QuotationProxy />,
    },*/
    /*{
      name: "PDF Quotation",
      key: "pdf-quotation-id",
      auth: true,
      route: "/quotations/pdf/:mode/:quotationId",
      //component: <QuotationPDFView />,
    },*/

    /*{
      name: "Manage Sales Order",
      key: "manage-salesorder",
      auth: true,
      route: "/salesorders/manage/:mode",
      //component: <SalesOrderProxy />,
    },*/
    /*{
      name: "Manage Sales Order",
      key: "manage-salesorder-id",
      auth: true,
      route: "/salesorders/manage/:mode/:salesorderId",
      //component: <SalesOrderProxy />,
    },*/
    /*{
      name: "PDF Sales Order",
      key: "pdf-salesorder-id",
      auth: true,
      route: "/salesorders/pdf/:mode/:salesorderId",
      //component: <SalesOrderPDFView />,
    },*/

    /*{
      name: "Manage Invoice",
      key: "manage-invoice",
      auth: true,
      route: "/invoices/manage/:mode",
      //component: <InvoiceProxy />,
    },*/
    /*{
      name: "Manage Invoice",
      key: "manage-invoice-id",
      auth: true,
      route: "/invoices/manage/:mode/:invoiceId",
      //component: <InvoiceProxy />,
    },*/
    /*{
      name: "PDF Invoice",
      key: "pdf-invoice-id",
      auth: true,
      route: "/invoices/pdf/:mode/:invoiceId",
      //component: <InvoicePDFView />,
    },*/

    /*{
      name: "Manage Supplier",
      key: "manage-supplier",
      auth: true,
      route: "/suppliers/manage/:mode",
      //component: <SupplierProxy />,
    },*/
    /*{
      name: "Manage Supplier",
      key: "manage-supplier-id",
      auth: true,
      route: "/suppliers/manage/:mode/:supplierId",
      //component: <SupplierProxy />,
    },*/
    /*{
      name: "Manage Purchase Order",
      key: "manage-purchaseorders",
      auth: true,
      route: "/purchaseorders/manage/:mode",
      //component: <PurchaseOrderProxy />,
    },*/
    /*{
      name: "Manage Purchase Order",
      key: "manage-purchaseorder-id",
      auth: true,
      route: "/purchaseorders/manage/:mode/:purchaseorderId",
      component: <PurchaseOrderProxy />,
    },*/
    /*{
      name: "PDF Purchase Order",
      key: "pdf-purchaseorder-id",
      auth: true,
      route: "/purchaseorders/pdf/:mode/:purchaseorderId",
      component: <PurchaseOrderPDFView />,
    },*/
  ];
};

export default navigate;
