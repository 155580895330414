import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { MuiContextProvider } from "context/MuiContext";
import { AuthContextProvider } from "context/AuthContext";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <MuiContextProvider>
        <App />
      </MuiContextProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
