const Role = Object.freeze({
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  SALESMANAGER: "SALESMANAGER",
  SALES: "SALES",
  // PURCHASES: "PURCHASES",
  ACCOUNT: "ACCOUNT",
  FINANCE: "FINANCE",
  // SERVICE: "SERVICE",
  MEMBER: "MEMBER",
});

const Permission = Object.freeze({
  // permission of dashboard (as shown on side nav bar)
  VIEW_SUPERADMIN_DASHBOARD: "VIEW_SUPERADMIN_DASHBOARD",
  VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
  // VIEW_MANAGER_DASHBOARD: "VIEW_MANAGER_DASHBOARD",
  VIEW_MEMBER_DASHBOARD: "VIEW_MEMBER_DASHBOARD",

  // VIEW_SALES_DASHBOARD: "VIEW_SALES_DASHBOARD",
  // VIEW_PURCHASES_DASHBOARD: "VIEW_PURCHASES_DASHBOARD",
  // VIEW_ACCOUNT_DASHBOARD: "VIEW_ACCOUNT_DASHBOARD",
  // VIEW_FINANCE_DASHBOARD: "VIEW_FINANCE_DASHBOARD",
  // VIEW_SERVICE_DASHBOARD: "VIEW_SERVICE_DASHBOARD",
  // VIEW_HIDE_DASHBOARD: "VIEW_HIDE_DASHBOARD",

  // permission of action (as shown on side nav bar)
  // VIEW_USERS_ACTION: "VIEW_USERS_ACTION",
  VIEW_CUSTOMERS_ACTION: "VIEW_CUSTOMERS_ACTION",
  VIEW_SUPPLIERS_ACTION: "VIEW_SUPPLIERS_ACTION",
  VIEW_QUOTATIONS_ACTION: "VIEW_QUOTATIONS_ACTION",
  VIEW_SALESORDERS_ACTION: "VIEW_SALESORDERS_ACTION",
  VIEW_INVOICES_ACTION: "VIEW_INVOICES_ACTION",
  VIEW_PURCHASEORDER_ACTION: "VIEW_PURCHASEORDER_ACTION",
  // VIEW_SERVICEREPORTS_ACTION: "VIEW_SERVICEREPORTS_ACTION",

  // permission for data (also use for firestore rules)
  PERFORM_SUPERADMIN_ACTION: "PERFORM_SUPERADMIN_ACTION",
  PERFORM_ADMIN_ACTION: "PERFORM_ADMIN_ACTION",

  READ_ALL_ROLES: "READ_ALL_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  READ_ROLE: "READ_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  // PURGE_ROLE: "PURGE_ROLE",

  READ_ALL_COMPANIES: "READ_ALL_COMPANIES",
  CREATE_COMPANY: "CREATE_COMPANY",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  READ_COMPANY: "READ_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",
  // PURGE_COMPANY: "PURGE_COMPANY",

  READ_ALL_USERS: "READ_ALL_USERS",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  READ_USER: "READ_USER",
  DELETE_USER: "DELETE_USER",
  // PURGE_USER: "PURGE_USER",

  READ_ALL_CUSTOMERS: "READ_ALL_CUSTOMERS",
  CREATE_CUSTOMER: "CREATE_CUSTOMER",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  READ_CUSTOMER: "READ_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  // PURGE_CUSTOMER: "PURGE_CUSTOMER",
  APPROVE_CUSTOMER: "APPROVE_CUSTOMER",
  REJECT_CUSTOMER: "REJECT_CUSTOMER",

  READ_ALL_SUPPLIERS: "READ_ALL_SUPPLIERS",
  CREATE_SUPPLIER: "CREATE_SUPPLIER",
  UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
  READ_SUPPLIER: "READ_SUPPLIER",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",
  PURGE_SUPPLIER: "PURGE_SUPPLIER",
  APPROVE_SUPPLIER: "APPROVE_SUPPLIER",
  REJECT_SUPPLIER: "REJECT_SUPPLIER",

  READ_ALL_QUOTATIONS: "READ_ALL_QUOTATIONS",
  CREATE_QUOTATION: "CREATE_QUOTATION",
  UPDATE_QUOTATION: "UPDATE_QUOTATION",
  READ_QUOTATION: "READ_QUOTATION",
  PRINT_QUOTATION: "PRINT_QUOTATION",
  SEND_QUOTATION: "SEND_QUOTATION",
  CONVERT_QUOTATION: "CONVERT_QUOTATION",
  DELETE_QUOTATION: "DELETE_QUOTATION",
  DELETE_QUOTATION_DRAFT: "DELETE_QUOTATION_DRAFT",
  // PURGE_QUOTATION: "PURGE_QUOTATION",
  APPROVE_QUOTATION: "APPROVE_QUOTATION",
  APPROVE_QUOTATION_L1: "APPROVE_QUOTATION_L1", // > 5k < 50k
  APPROVE_QUOTATION_L2: "APPROVE_QUOTATION_L2", // < 50k
  REJECT_QUOTATION: "REJECT_QUOTATION",
  REJECT_QUOTATION_L1: "REJECT_QUOTATION_L1", // > 5k < 50k
  REJECT_QUOTATION_L2: "REJECT_QUOTATION_L2", // < 50k
  EXPORT_QUOTATIONS: "EXPORT_QUOTATIONS",

  READ_ALL_SALESORDERS: "READ_ALL_SALESORDERS",
  CREATE_SALESORDER: "CREATE_SALESORDER",
  UPDATE_SALESORDER: "UPDATE_SALESORDER",
  READ_SALESORDER: "READ_SALESORDER",
  PRINT_SALESORDER: "PRINT_SALESORDER",
  COMPLETE_SALESORDER: "COMPLETE_SALESORDER",
  CANCEL_SALESORDER: "CANCEL_SALESORDER",
  CONVERT_SALESORDER: "CONVERT_SALESORDER",
  APPROVE_SALESORDER: "APPROVE_SALESORDER",
  APPROVE_SALESORDER_L1: "APPROVE_SALESORDER_L1",
  APPROVE_SALESORDER_L2: "APPROVE_SALESORDER_L2",
  REJECT_SALESORDER: "REJECT_SALESORDER",
  REJECT_SALESORDER_L1: "REJECT_SALESORDER_L1",
  REJECT_SALESORDER_L2: "REJECT_SALESORDER_L2",
  DELETE_SALESORDER: "DELETE_SALESORDER",
  // PURGE_SALESORDER: "PURGE_SALESORDER",
  EXPORT_SALESORDERS: "EXPORT_SALESORDERS",
  EDIT_DEPOSIT: "EDIT_DEPOSIT",

  READ_ALL_INVOICES: "READ_ALL_INVOICES",
  CREATE_INVOICE: "CREATE_INVOICE",
  UPDATE_INVOICE: "UPDATE_INVOICE",
  READ_INVOICE: "READ_INVOICE",
  PRINT_INVOICE: "PRINT_INVOICE",
  RECEIPT_INVOICE: "RECEIPT_INVOICE",
  CLOSE_INVOICE: "CLOSE_INVOICE",
  SEND_INVOICE: "SEND_INVOICE",
  // APPROVE_INVOICE: "APPROVE_INVOICE",
  // REJECT_INVOICE: "REJECT_INVOICE",
  DELETE_INVOICE: "DELETE_INVOICE",
  // PURGE_INVOICE: "PURGE_INVOICE",
  EXPORT_INVOICES: "EXPORT_INVOICES",

  READ_ALL_PURCHASEORDERS: "READ_ALL_PURCHASEORDERS",
  CREATE_PURCHASEORDER: "CREATE_PURCHASEORDER",
  UPDATE_PURCHASEORDER: "UPDATE_PURCHASEORDER",
  READ_PURCHASEORDER: "READ_PURCHASEORDER",
  PRINT_PURCHASEORDER: "PRINT_PURCHASEORDER",
  COMPLETE_PURCHASEORDER: "COMPLETE_PURCHASEORDER",
  CANCEL_PURCHASEORDER: "CANCEL_PURCHASEORDER",
  CONVERT_PURCHASEORDER: "CONVERT_PURCHASEORDER",
  APPROVE_PURCHASEORDER: "APPROVE_PURCHASEORDER",
  REJECT_PURCHASEORDER: "REJECT_PURCHASEORDER",
  DELETE_PURCHASEORDER: "DELETE_PURCHASEORDER",
  PURGE_PURCHASEORDER: "PURGE_PURCHASEORDER",
  SEND_PURCHASEORDER: "SEND_PURCHASEORDER",
  EXPORT_PURCHASEORDERS: "EXPORT_PURCHASEORDERS",
});

export { Role, Permission };
